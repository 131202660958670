var DK = DK || {};
DK.Tracking = {
  Listrak: {
    // Determines whether or not Listrak Tracking in enabled
    isAvailable: function () {
      return typeof _ltk !== 'undefined';
    },
    // Initialize the Listrak Tracking module
    init: function () {
      this.registerEvents();
    },
    // Register all events that are associated with this module
    registerEvents: function () {
      this.Form.submitForm();
      this.Checkout.createAccountUpdateSubscriptions();
      this.PreferenceCenter.registerPrefCenterChangeEmailEvent();
      this.PreferenceCenter.registerPrefCenterEmailUnsubscribeEvent();

      // Subscribe to emails footer
      this.Footer.registerFooterEmailSignupEvent();
    },
    setInputField: function (field, value, placeholder) {
      if (value) {
        field.val(value);
      } else {
        field.attr('placeholder', placeholder);
      }
    },
    addVoltageMediaEmailSignupTrackingPixel: function (email) {
      let ran = Math.round(Math.random() * 100000000);
      let imgSrc = `https://tracking.voltagesearch.com/image.gif?aid=410&tid=
				${email}&ttp=Email Signup&cnt=1&val=1&sRng=${ran}&ret=0`;
      let img = document.createElement('img');
      img.src = imgSrc;
      document.getElementsByTagName('body')[0].appendChild(img);
    },
    // A function to call the SOAP endpoint that is used to retrieve
    // A user's email preferences in order to preselect the preferences
    // On the email preferences form.
    getEmailPreferences: function (email, parent) {
      let spinner = new Spinner();
      let $form = $(`${parent} .js-email-pref-form`);
      $.ajax({
        url: '/email/preferences.json',
        method: 'POST',
        data: {
          email: email
        },
        beforeSend: function () {
          DK.displayBlocker($form);
          spinner.spin($form.get()[0]);
        }
      }).done(function (data) {
        $(`${parent} input[name="emailPreferences"]`).each(function () {
          if ($.inArray($(this).data('preference-id'), data.emailPreferences) > -1) {
            $(this).prop('checked', true);
            $(this).parent().addClass('selected');
          } else {
            $(this).prop('checked', false);
          }
        });
        if ($('#email-preferences-page').length > 0) {
          DK.Tracking.Listrak.setInputField($('#preferences-email'), email, 'Email Address');
          DK.Tracking.Listrak.setInputField($('#preferences-name'), data.name, 'Name');
          DK.Tracking.Listrak.setInputField($('#preferences-postalCode'), data.postalCode, 'Postal Code');
        }

        // SITE-3552 pre-fill modal email address with the value already entered
        DK.Tracking.Listrak.setInputField($('#signup-email'), email, 'Email Address');
      }).fail(function (data) {
        if ($('#prefs-error').length == 0) {
          $('.js-email-pref-form').prepend(`<div id='prefs-error' class='alert alert-danger'>
							We were unable to retrieve email preferences.
							Please try again later.</div>`);
        }
      }).always(function (jqXHR, textStatus) {
        DK.hideBlocker(parent + ' .js-email-pref-form');
        spinner.stop();
      });
    },
    updateEmailPreferences: function ($parent, data) {
      let spinner = new Spinner();
      $.ajax({
        url: '/listrak/update-prefs.json',
        method: 'POST',
        traditional: true,
        data: data,
        beforeSend: function () {
          DK.displayBlocker($parent);
          spinner.spin($parent.get()[0]);
        }
      }).done(function (data) {
        if (data.signupSource == 2391250) {
          //signup source is preference center
          if (DK.SITE_TYPE === 'desktop' && data.status == 'success') {
            window.location.href = '/email/preferences-success';
          } else if ($('#prefs-status').length == 0) {
            $parent.prepend(`<div id='prefs-status' class='alert alert-${data.status}'>
								${data.statusMessage}</div>`);
          }
        } else if (data.signupSource == 2391248 && data.status == 'success') {
          //signup source is footer
          window.location.href = '/email/preferences-success';
        } else if ($('#prefs-status').length == 0) {
          $('.ac__alert').bsAlert({
            type: 'alert-' + data.status,
            content: data.statusMessage,
            fadeout: false,
            icon: 'dk dk-check-900'
          });
        }
      }).fail(function (data) {
        if ($('#prefs-error').length == 0) {
          $parent.prepend(`<div id='prefs-error' class='alert alert-danger'>
							Unable to set email preferences. Please try again later.</div>`);
        }
      }).always(function (jqXHR, textStatus) {
        DK.hideBlocker($parent);
        spinner.stop();
      });
    },
    /* Account Registration related functions, events, and tracking data */
    Register: {
      registerUserPreferences: function (parent, userData, emailPrefs) {
        let data = {
          email: userData['email'],
          signupSource: 2391253,
          emailPreferenceIds: emailPrefs,
          name: userData['name']
        };
        DK.Tracking.Listrak.updateEmailPreferences($(parent), data);
      },
      parseFormElements: function ($form) {
        let userData = $form.serializeArray().reduce(function (accObj, item) {
          accObj[item.name] = item.value;
          return accObj;
        }, {});
        return userData;
      }
    },
    /* Function for submitting email preferences form */
    Form: {
      submitForm: function () {
        $('.js-email-pref-form:not([data-authentication])').on('submit', function (e) {
          e.preventDefault();
          let emailParent = $(this).parent(),
            alertLocation = $('.ac__alert');
          if ($('#modal-create-account').length) {
            //skip form submission as the create account modal is handled differently
            return false;
          }
          let emailPrefs = [];
          let signupSource = $(this).find('input[name="signupSource"]').val();
          $(this).find('input[name="emailPreferences"]:checked').each(function () {
            emailPrefs.push($(this).data('preference-id'));
          });
          let email = $(this).find('input[name="email"]').val();
          let name = $(this).find('input[name="name"]').val();
          let postalCode = $(this).find('input[name="postalCode"]').val();
          let data = {
            email: email,
            signupSource: signupSource,
            emailPreferenceIds: emailPrefs,
            name: name,
            postalCode: postalCode
          };
          if (emailParent.hasClass('ac-email-prefs')) {
            DK.Tracking.Listrak.updateEmailPreferences(alertLocation, data);
          } else {
            DK.Tracking.Listrak.updateEmailPreferences($(this), data);
          }
          if ($('#create-account').length) {
            DK.Tracking.Listrak.addVoltageMediaEmailSignupTrackingPixel(email);
          }
          if ($('#modal-email-pref').length) {
            bootstrap.Modal.getInstance($(e.currentTarget).parents('.modal')[0]).hide();
          }
        });
      }
    },
    /* Checkout related functions, events, and tracking data */
    Checkout: {
      /* Create account user update email preferences(Desktop) */
      createAccountUpdateSubscriptions: function () {
        $('#modal-create-account .js-email-pref-form').on('submit', function (e) {
          e.preventDefault();
          $.ajax({
            url: this.action,
            type: this.method,
            data: $(this).serialize(),
            complete: function () {
              DK.hideBlocker('#modal-create-account');
              $('.spinner').remove();
            }
          }).done(function (data, textStatus, jqXHR) {
            if (jqXHR.status === 202) {
              // Successful form submission returns a HTTP 202 Accepted
              // status code
              let userData = DK.Tracking.Listrak.Register.parseFormElements($('#modal-create-account form'));
              let emailPrefs = [];
              $('#modal-create-account input[name="emailPreferences"]:checked').each(function () {
                emailPrefs.push($(this).data('preference-id'));
              });
              DK.Tracking.Listrak.Register.registerUserPreferences('#modal-create-account', userData, emailPrefs);
              let checkedPrefs = document.querySelectorAll('#modal-create-account form input[name="emailPreferences"]:checked');
              if (checkedPrefs.length > 0) {
                DK.Tracking.Listrak.addVoltageMediaEmailSignupTrackingPixel(userData['email']);
              }
              bootstrap.Modal.getInstance($(e.currentTarget).parents('.modal')[0]).hide();
            } else {
              // Update modal content (.modal() is used here otherwise
              // the upper right close icon disappears on multiple
              // error submissions)
              $('#email-pref-modal modal-body-content').html(data);
              bootstrap.Modal.getOrCreateInstance($('#email-pref-modal')[0]).show();
              // Load email preferences when account creation fails
              let email = $('.account-login').text();
              DK.Tracking.Listrak.getEmailPreferences(email, '#modal-create-account');
            }
          });
        });
      }
    },
    PreferenceCenter: {
      registerPrefCenterChangeEmailEvent: function () {
        $('.js-change-email').on('submit', function (e) {
          e.preventDefault();
          let email = $('#preferences-email').val();
          let newEmail = $('#newEmail').val();
          if (email && newEmail) {
            $.ajax({
              url: '/listrak/change-email.json',
              method: 'POST',
              data: {
                oldEmail: email,
                newEmail: newEmail
              }
            }).done(function (data) {
              $('#change-email-msg').remove();
              $('.js-change-email').prepend(`<div id='change-email-msg' class='alert
									alert-${data.status}'>${data.statusMessage}</div>`);
            }).fail(function (data) {
              $('#change-error').remove();
              $('.js-change-email').prepend(`<div id='change-error' class='alert alert-danger'>We were
									unable to change your email. Please try again later.</div>`);
            }).always(function (jqXHR, textStatus) {
              DK.hideBlocker('.js-change-email');
              $('.spinner').remove();
            });
          } else {
            if (!email && $('#email-preferences-page .is-invalid').length < 1) {
              $('#preferences-email').addClass('is-invalid');
              $('#preferences-email').after(`<small class='invalid-feedback'>Please enter a valid email
								</small>`);
            }
            if (!newEmail && $('.js-change-email .is-invalid').length < 1) {
              $('#newEmail').addClass('is-invalid');
              $('#newEmail').after(`<small class='invalid-feedback'>Please enter a valid email
								</small>`);
            }
          }
        });
      },
      registerPrefCenterEmailUnsubscribeEvent: function () {
        $('#preferences-unsubscribe').on('click', function (e) {
          e.preventDefault();
          let email = $('#preferences-email').val();
          let href = `/listrak/unsubscribe?email=${encodeURIComponent(email)}`;
          if (!email && $('#email-preferences-page .is-invalid').length < 1) {
            $('#preferences-email').addClass('is-invalid');
            $('#preferences-email').after(`<small class='invalid-feedback'>Please enter a valid email
							</small>`);
          } else {
            window.location.href = href;
          }
        });
        $('#signup-unsubscribe').on('click', function (e) {
          e.preventDefault();
          let email = $('#signup-email').val();
          let href = `/listrak/unsubscribe?email=${encodeURIComponent(email)}`;
          if (!email && $('#signup-modal .is-invalid').length < 1) {
            $('#signup-email').addClass('is-invalid');
            $('#signup-email').after(`<small class='invalid-feedback'>Please enter a valid email
							</small>`);
          } else {
            window.location.href = href;
          }
        });
      }
    },
    Footer: {
      registerFooterEmailSignupEvent: function () {
        $('#email-sign-up').on('click', () => {
          let email = $('#footer-email').val();
          DK.Tracking.Listrak.getEmailPreferences(email, '#signup-modal .modal-content');
        });
        $('#footer-email').on('keydown', function (e) {
          if (e.key == 'Enter' || e.which == 13 || e.keyCode == 13) {
            $('#email-sign-up').trigger('click');
          }
        });
      }
    }
  },
  Google: {
    setFeedbackClientId: function () {
      if (typeof ga != 'undefined') {
        ga(function (tracker) {
          let gaClientId = tracker.get('clientId');
          $('body').attr('data-google-client-id', gaClientId);
        });
      }
    }
  }
};
$(function () {
  // Register the Listrak Events
  DK.Tracking.Listrak.init();
  DK.Tracking.Google.setFeedbackClientId();
  if ($('#email-preferences-page').length > 0) {
    //Get email from url
    let regexResult = /[\?&]email=([^&#]*)/.exec(window.location.href);
    urlParam = regexResult != null ? regexResult[1] : 0;
    let email = $('#preferences-email').val() || urlParam;
    if (email != null && email.length > 0) {
      $('#preferences-email').val(email);
      DK.Tracking.Listrak.getEmailPreferences(email, '#email-preferences-page');
    }
  }
});