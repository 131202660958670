(function ($) {
  $.fn.cookieconsent = function (options, event) {
    let $element = $(this);

    // Set defaults
    let settings = $.extend({
      title: 'Cookies & Privacy',
      message: 'Our website uses cookies to give you the best online shopping experience. ',
      link: '/help/privacy',
      delay: 1000,
      expires: 90,
      moreInfoLabel: 'More information',
      acceptBtnLabel: 'Accept Cookies'
    }, options);
    let myCookie = getCookie('cookieConsent');
    if (!myCookie || event == 'reinit') {
      // Remove all instances of the cookie message so it's not duplicated
      $('#dk-cookie-message').remove();

      // Display cookie message on page
      let cookieMessage = `<div id="dk-cookie-message" class="cookie">
					<div class="cookie__content">
						<p class="cookie__msg">${settings.message}
							<a class="cookie__more" href="${settings.link}">
							${settings.moreInfoLabel}</a>
						</p>
						<button id="dk-cookie-accept" class="cookie__btn btn btn-primary"
						type="button">${settings.acceptBtnLabel}</button>
					</div>
				</div>`;
      setTimeout(function () {
        $($element).append(cookieMessage);
      }, settings.delay);

      //Add space to footer when cookie consent message is displayed
      let spacingElement = document.createElement('div');
      spacingElement.setAttribute('id', 'cookie-consent-space');
      $('#footer-3').append(spacingElement);

      // When accept button is clicked set cookie value
      $('body').on('click', '#dk-cookie-accept', function () {
        // Set cookie
        setCookieValue(true, settings.expires);
        //remove the extra space
        $('#cookie-consent-space').remove();
      });
    } else {
      let cookieVal = true;
      if (myCookie == 'false') {
        cookieVal = false;
      }
      setCookieValue(cookieVal, settings.expires);
    }
  };

  /* Set the cookie with a boolean value of true. */
  let setCookieValue = function (value, expiryDays) {
    setCookie('cookieConsent', value, expiryDays);
    $('#dk-cookie-message').fadeOut('fast', function () {
      $(this).remove();
    });
  };

  /* Sets cookie with expiration, path and secure */
  let setCookie = function (name, value, expiry_days) {
    let d = new Date();
    d.setTime(d.getTime() + expiry_days * 24 * 60 * 60 * 1000);
    let expires = `expires=${d.toUTCString()}`,
      secure = 'secure=true';
    document.cookie = `${name}=${value};${expires};path=/;${secure};`;
    return getCookie(name);
  };

  /* Gets cookie called 'name'. */
  let getCookie = function (name) {
    let cookie_name = name + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cookie_name) === 0) {
        return c.substring(cookie_name.length, c.length);
      }
    }
    return false;
  };
})(jQuery);
$('body').cookieconsent();