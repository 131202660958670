/**
Holds any javascript functions or letiables that fit the following
criteria:
	1) Should be available to any other javascript method

If a letiable or function does not fit all criteria, please consider placing
it into an different javascript file.
*/

var DK = DK || {};
DK = {
  // Set the application context root for any javascript files
  CONTEXT_ROOT: $("meta[name='context']").attr('content'),
  SITE_TYPE: $("meta[name='siteType']").attr('content'),
  url: function (uri) {
    if (!uri) {
      return uri;
    }
    if (uri[0] === '/') {
      return DK.CONTEXT_ROOT + uri;
    } else {
      return DK.CONTEXT_ROOT + '/' + uri;
    }
  },
  values: function (map) {
    let list = [];
    let i = 0;
    for (let key in map) {
      if (map.hasOwnProperty(key)) {
        list[i] = map[key];
      }
      i++;
    }
    return list;
  },
  keys: function (map) {
    let list = [];
    let i = 0;
    for (let key in map) {
      if (map.hasOwnProperty(key)) {
        list[i] = key;
      }
      i++;
    }
    return list;
  },
  displayBlocker: function (selector, addRelativeToParent) {
    /* If the selector is on the body, cover the entire page, otherwise
    set height at 100% */
    let height = selector === 'body' ? $(document).height() : '100%';
    let $blocker = $('<div>').css({
      position: 'absolute',
      'z-index': '9990',
      opacity: '0.75',
      width: '100%',
      height: height,
      'background-color': '#FFF'
    }).addClass('blocker');
    $blocker.prependTo(selector);
    if (addRelativeToParent || addRelativeToParent == 'undefined') {
      $blocker.parent().css({
        position: 'relative'
      });
    }
  },
  hideBlocker: function (selector) {
    $(selector).find('.blocker').remove();
  },
  /**
   * Fade an alert-box element in and out after a small period of time.
   * Generally used for an AJAX status update to notify the user if the request
   * succeded or failed.
   * @param $alertDiv .alert-box element to display and hide.
   * @param alertMessage Message to be displayed in the alert
   * @param alertType Type of alert - possible values are 'success', 'alert',
   *        and 'secondary'
   */
  toggleAlert: function ($alertDiv, alertMessage, alertType) {
    $alertDiv.removeClass('alert-success alert-warning alert-danger');
    $alertDiv.addClass(alertType);
    $alertDiv.text(alertMessage);
    if (alertType === 'alert') {
      // Keep error messages up longer than success messages
      $alertDiv.fadeIn().delay('5000').fadeOut();
    } else {
      $alertDiv.fadeIn().delay('2000').fadeOut();
    }
  },
  Scroller: {
    init: function () {
      if (document.documentElement.scrollTop === 0 && !location.hash) {
        setTimeout(function () {
          if (!scrollY) window.scrollTo(0, 0);
        }, 1000);
      }
    }
  },
  /**
   * Toggling functionality
   *
   * Usage:
   * 		Add toggle-me class to element
   * 		Add data-hide attribute to element
   * 			- i.e. <div data-hide="#importantInfo"/>
   *			- This tag is supplied with the target element (using standard element selectors, # for id and . for class)
   *
   * See top-menu.vm (search-bar) for example usage
   */
  toggleMe: function () {
    $('.toggle-me').on('click', function (e) {
      e.preventDefault();

      // Bypass any toggling when authentication is required on
      // an element.
      if ($(this).data('login') !== undefined) {
        return;
      }
      let dataElement = $(this).attr('data-hide');
      if ($(dataElement).hasClass('toggle-me-slide')) {
        $(dataElement).slideToggle();
      } else {
        $(dataElement).toggle();
      }
      $(dataElement).find('input').first().focus();
      $(this).toggleClass('toggle-on');
    });
    $(document).on('click', function (e) {
      let $target = $(e.target),
        isToggleButton = $target.hasClass('toggle-me') || $target.parents('.toggle-me').length,
        isToggleSlide = $target.parents('.toggle-me-slide').length;
      if (isToggleButton || isToggleSlide) {
        return;
      }
      $('.toggle-me-slide').hide();
    });
    let timeout;
    $.each($('.toggle-me'), function (i, element) {
      // Don't un-toggle the elements when it is a product
      // listing wish element.
      if ($(this).attr('data-hide') == '#gift-lists') {
        return;
      }
      let dataElement = $(this).attr('data-hide');
      $(dataElement).on('click', function () {
        clearTimeout(timeout);
      });
      $(dataElement + ' input').on('focusin', function () {
        clearTimeout(timeout);
      });
      $(dataElement).bind('focusout', function () {
        timeout = setTimeout(function () {
          $(dataElement).hide();
          $('.toggle-on').removeClass('toggle-on');
        }, 150);
      });
    });
  },
  /**
   * jQuery AJAX failure handler for all AJAX requests that checks the
   * request to see if there was an authentication error. If the user was
   * not authenticated for the request, it will attempt to display the
   * login page to the user by forcing a page refresh - the application
   * should detect if the user has authentication for the requested page
   * or not and display the login screen appropriately.
   * If html for the account side panel is included in the response, the
   * side panel will be opened with the login form.
   */
  ajaxAuthenticationErrorHandler: function (event, jqxhr, settings, thrownError) {
    if (jqxhr.status === 403) {
      const data = jqxhr.responseJSON;
      if (data.accountHtml) {
        const redirectUrl = window.location.href;
        const redirectInput = $(`<input type="hidden" value="${redirectUrl}" name="loginRedirect" />`);
        const $accountDropdown = DK.getScreenSize() === 'mobile' ? $('#account-offcanvas') : $('#h-acct__oc');
        $accountDropdown.empty().append(data.accountHtml);
        $accountDropdown.find('form').append(redirectInput);
        $accountDropdown.offcanvas('show');
      } else {
        window.location.reload();
      }
    }
  },
  displayLoading: function (element) {
    $(element).show();
    $('html, body').scrollTop(0);
  },
  /**
   * Display a login prompt inside of a modal dialog when the selector is
   * clicked.
   * @param selector [String] CSS selector for the element(s) to have an
   *                 active event on
   * @param modalSelector [String] CSS selector for the modal dialog.
   */
  loginHandler: function (selector, modalSelector) {
    $(selector).on('click mousedown', function (e) {
      e.preventDefault();
      let $modalSelector = $(modalSelector),
        redirectUrl = window.location.href,
        redirectInput = $(`<input type="hidden" value="${redirectUrl}" name="loginRedirect" />`);

      // Insert an hidden input so that the form knows where to
      // redirect back to.
      $modalSelector.find('form').append(redirectInput);

      // Remove the input when the modal is closed so that any
      // other way of the login modal opening is not affected.
      $modalSelector[0].addEventListener('hide.bs.modal', function () {
        $modalSelector.find('input[name=loginRedirect]').remove();
      });
    });
  },
  /**
   * Remove updateBookmarks query parameter on page load so users can bookmark the
   * page without having the query parameter in the url that gets bookmarked.
   */
  bookmarkHandler: function () {
    let uri = window.location.toString();
    if (uri.indexOf('updateBookmarks') > 0) {
      //if there are multiple query params, remove updateBookmars=true& or
      //if it is the only query param, remove ?updateBookmarks=true
      let clean_uri = uri.replace(/updateBookmarks=true&?|\?updateBookmarks=true$/, '');
      window.history.replaceState({}, document.title, clean_uri);
    }
  },
  //Textarea max length count
  maxLengthCount: function () {
    let textareas = $('form').find('textarea[maxlength]');
    //if the text area has a maxlength, add counter
    textareas.each(function () {
      let textarea = $(this);
      if (textarea.attr('maxlength') !== undefined) {
        let textareaParent = textarea.parent(),
          maxLength = textarea.attr('maxlength'),
          maxcountDiv = $(`<small><span class="maxcount">${maxLength}
						</span> characters left</small>`);
        textareaParent.append(maxcountDiv);
        let maxcount = textareaParent.find('span.maxcount');
        //Start counting characters when user starts typing
        textarea.on('keyup', function () {
          let length = textarea.val().length;
          length = maxLength - length;
          //If the length is shorter than 20 characters, make the text red//
          length < 20 ? maxcount.addClass('text-danger') : maxcount.removeClass('text-danger');
          maxcount.empty().text(length);
        });
      }
    });
  },
  /**
   * Initializes the lazysizes plugin to display images after page
   * load.
   */
  lazy: function () {
    // SITE-3714 force lazy load of invisible print header images
    $('#print-header img.lazyload').each(function () {
      $(this).attr('src', $(this).attr('data-original'));
    });
  },
  //Taken from https://gist.github.com/stinoga/8101816#gistcomment-1781338
  queryStringUrlReplacement: function (url, param, value) {
    let re = new RegExp('[\\?&]' + param + '=([^&#]*)'),
      match = re.exec(url),
      delimiter,
      newString;
    if (match === null) {
      // append new param
      let hasQuestionMark = /\?/.test(url);
      delimiter = hasQuestionMark ? '&' : '?';
      newString = url + delimiter + param + '=' + value;
    } else {
      delimiter = match[0].charAt(0);
      newString = url.replace(re, delimiter + param + '=' + value);
    }
    return newString;
  },
  /**
   * Checks the page to see if a dynamic element created by ads.js is on
   * the page. This is used to detect if ad blocking is enabled or not.
   * @returns {boolean} if ad blocking is enabled or not
   */
  adBlockEnabled: function () {
    return document.getElementById('nDRHYAGxewuh') === null;
  },
  log: function (params) {
    $.ajax({
      url: '/log',
      method: 'GET',
      data: params
    });
  },
  getScreenSize: function () {
    const screenSize = $(window).width() > 992 ? 'desktop' : 'mobile';
    return screenSize;
  }
};

/* A call back if you click outside an element */
jQuery.fn.clickOutside = function (callback) {
  let $me = this;
  $(document).on('mouseup', function (e) {
    if (!$me.is(e.target) && $me.has(e.target).length === 0) {
      callback.apply($me);
    }
  });
};
$(function () {
  if (window.addEventListener) {
    window.addEventListener('load', DK.Scroller.init, false);
  } else if (window.attachEvent) {
    window.attachEvent('onload', DK.Scroller.init);
  }
  $('.ajax-overlay').on('click', function (e) {
    e.preventDefault();
    return false;
  });
  DK.toggleMe();
  DK.loginHandler('[data-login]', '#login-modal');
  DK.bookmarkHandler();
  DK.lazy();
  DK.maxLengthCount();
  $(document).ajaxError(DK.ajaxAuthenticationErrorHandler);

  // Handle the close icon for alert boxes
  $(document).on('click', '.alert-box > .close', function (e) {
    $(e.target.parentElement).fadeOut();
  });
});
(function ($) {
  /**
   * Initialize Google Analytics event handlers.
   * The following data- attributes are required on the element to be tracked:
   * - data-ga-event The event category to classify events under
   * - data-ga-event-action The event action to classify what caused an event
   * - data-ga-event-label The event label (or data) to be tracked
   */
  $('[data-ga-event]').each(function (index, el) {
    let self = $(el);
    let category = self.data('gaEvent'),
      action = self.data('gaEventAction'),
      handlerAction = self.data('handlerAction'),
      label = self.data('gaEventLabel');
    if (handlerAction === undefined) {
      handlerAction = action;
    }
    self.on(handlerAction, function (e) {
      if (typeof ga !== 'undefined') {
        ga('send', 'event', category, action, label);
      }
    });
  });
})(jQuery);
$(function () {
  //disable forms on submission to prevent DFS
  $('form[data-disable-on-submit]').on('submit', () => {
    let spinner = new Spinner();
    // Place blocker on entire page
    //used to disable both buttons in the checkout process
    let $content = $('body');
    DK.displayBlocker($content, true);
    spinner.spin($content.get()[0]);
  });
});

// ACCOUNT LOGIN FORM, RETURN CUSTOMER LOGIN, MANAGE PERSONAL LOGIN AND CREATE ACCOUNT FORM LOGIN
$(function () {
  // Get the input field
  let password = document.querySelectorAll('[data-caps]');
  let arr = Array.from(password);

  // When the user presses any key on the keyboard, run the function
  arr.forEach(function (el) {
    // Get the warning text
    let text1 = el.closest('.input-group').nextElementSibling;
    el.addEventListener('keypress', function (event) {
      // If "caps lock" is pressed, display the warning text
      if (event.getModifierState('CapsLock')) {
        text1.style.display = 'block';
      } else {
        text1.style.display = 'none';
      }
    });
    el.addEventListener('blur', () => {
      //If you click outside the input, hide the capslock message
      text1.style.display = 'none';
    });
  });
});