/**
 * Enable popover functionality with custom content. A data-body attribute
 * can be specified in order to use an element on the page as the popover
 * content.
 * Added sanitize function which magically makes it work on IE 11... Witchcraft
 */
//  var exampleEl = document.querySelector('[data-bs-toggle="popover"]');
//  var popover = new bootstrap.Popover(exampleEl, {
// 	 sanitizeFn: function (content) {
// 		 return content
// 	 },
// 	 content: function() {
// 		 var content = $(this).data('body');
// 		 if (content && content.startsWith('#')) {
// 			 return $(content).html();
// 		 }
// 		 return content;
// 	 }
// 	})

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
let popoverList = popoverTriggerList.map(function (element) {
  return new bootstrap.Popover(element, {
    sanitizeFn: function (content) {
      return content;
    },
    html: true
  });
});

/*Enable Tooltip styles */
let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl);
});
// * Added sanitize function which magically makes it work on IE 11... Witchcraft
// */
// $('[data-bs-toggle="tooltip"]').tooltip({
// 	sanitizeFn: function (content) {
// 		return content;
// 	}
// })

/**
 * Enable remotely loading modal content via the Bootstrap modals. A modal
 * with data-remote will be loaded via an AJAX request. The modal template
 * must already be on the page (see the #modal template macro).
 */
// $(window).on("load", function() {
const modalLoadedEvent = new Event('loaded.dk.modal');
$(document).on('show.bs.modal', '.modal', function (e) {
  var target = $(e.relatedTarget),
    spinner = new Spinner(),
    $modal = $(this),
    $content = $modal.find('.modal-content');
  if (target.length) {
    let hasAltTitle = target[0].hasAttribute('data-bs-title');
    if (hasAltTitle) {
      $modal.find('.modal-title').text(target.data('bsTitle'));
    } else {
      $modal.find('.modal-title').text(target.data('title'));
    }
  }
  if (target.length && target.data('remote') !== undefined) {
    $content.find('.modal-body-content').empty();
    $.ajax({
      url: target.attr('href'),
      beforeSend: function () {
        DK.displayBlocker($content);
        spinner.spin($content.get()[0]);
      },
      complete: function () {
        DK.hideBlocker($content);
        spinner.stop();
      }
    }).done(function (data) {
      $modal.find('.modal-body-content').html(data);
      $modal.each(function () {
        this.dispatchEvent(modalLoadedEvent);
      });
    });
  }
}).on('shown.bs.modal', '.modal', function (e) {
  let target = $(e.relatedTarget);
  if (target.length && target.data('recaptcha') === true && !window.recaptcha) {
    // Handle the login modal's requirement for ReCaptcha by
    // lazy-loading their library when the modal is opened on pages
    // that don't already load the recaptcha library.
    let recaptchaScriptTag = document.createElement('script');
    recaptchaScriptTag.type = 'text/javascript';
    recaptchaScriptTag.async = true;
    recaptchaScriptTag.src = 'https://www.google.com/recaptcha/api.js';
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(recaptchaScriptTag, s);
  }
});

//Holdover from sparkys event-handlers.js for infobox?
document.addEventListener('alert-info-event', function (e) {
  const messageBoxId = 'dk-alert-info-message';
  const currentMessage = document.querySelector('#' + messageBoxId);
  if (currentMessage !== null) {
    currentMessage.remove();
  }
  const content = document.querySelector('.body-content');
  const row = document.createElement('div');
  row.classList.add('row');
  row.setAttribute('id', messageBoxId);
  const alert = document.createElement('div');
  alert.classList.add('alert-info', 'alert');
  alert.textContent = e.detail.message;
  row.appendChild(alert);
  content.insertBefore(row, content.children[0]);
  $('html').scrollTop(0);
});