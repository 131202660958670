$(function () {
  let validator = $('form.needs-validation').dkvalidator({
    errorMessage: true,
    successClass: true
  });
});

//Show Password toggle for DK float labels
$(function () {
  let visibleForm = $('input[type=password] + label').parents('form:visible');
  let eyeslashShow = $(`<div role="button" class="fl-ico fl-ico--pw-toggle fl-ico--pw-show"
		aria-label="Show password as plain text"></div>`);
  if (!visibleForm.find('.fl-ico--pw-toggle').length) {
    $('input[type=password] ~ span').after(eyeslashShow);
  }
  $('.fl-ico--pw-toggle').on('click', function (e) {
    //When you click on the eye toggle,
    //Toggle the input type from password to text
    //Then also toggle the eye icon.
    let eyelash = $(e.currentTarget);
    let input = eyelash.siblings('input');
    if (input.attr('type') === 'password') {
      input.attr('type', 'text');
    } else if (input.attr('type') === 'text') {
      input.attr('type', 'password');
    }
    eyelash.toggleClass('fl-ico--pw-show fl-ico--pw-hide');
  });
});

//Show Password toggle for Bootstrap float labels
function showBsPassword(e) {
  let visibilityToggle = $(e);
  let input = visibilityToggle.parent().find('input');
  if (input.attr('type') === 'password') {
    input.attr('type', 'text');
  } else if (input.attr('type') === 'text') {
    input.attr('type', 'password');
  }
  visibilityToggle.toggleClass('fl-ico--pw-show fl-ico--pw-hide');
}