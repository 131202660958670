// Based off
// https://raw.githubusercontent.com/emretulek/jbvalidator/main/src/jbvalidator.js

// let validator = $('form.needs-validation').dkvalidator({
// 	errorMessage: true,
// 	successClass: true,
// });

// //custom validate methode
// validator.validator.custom = function(el, event){
// 	let passwordLength = $(el).val().length;
// 		if($(el).is('[name=password]') && passwordLength > 0 && passwordLength < 5){
// 				return 'Your password is too weak.';
// 		}
// }

(function ($) {
  'use strict';

  $.fn.dkvalidator = function (options) {
    let defaults = {
      language: '',
      errorMessage: true,
      successClass: false,
      html5BrowserDefault: false,
      validFeedBackClass: 'valid-feedback',
      invalidFeedBackClass: 'invalid-feedback',
      validClass: 'is-valid',
      invalidClass: 'is-invalid'
    };
    options = $.extend({}, defaults, options);
    let FORM = this;
    let errorMessages = {
      maxValue: 'Value must be less than or equal to %s.',
      minValue: 'Value must be greater than or equal to %s.',
      maxLength: 'Please lengthen this text to %s characters or less (you are currently using %s characters).',
      minLength: 'Please lengthen this text to %s characters or more (you are currently using %s characters).',
      minSelectOption: 'Please select at least %s options.',
      maxSelectOption: 'Please select at most %s options.',
      groupCheckBox: 'Please select at least %s options.',
      equal: 'This field does not match with %s field.',
      fileMinSize: 'File size cannot be less than %s bytes.',
      fileMaxSize: 'File size cannot be more than %s bytes.',
      number: 'Please enter a number.',
      HTML5: {
        valueMissing: {
          INPUT: {
            default: 'Please fill out this field.',
            checkbox: 'Please check this box.',
            radio: 'Please select one of these options.',
            file: 'Please select a file.'
          },
          SELECT: 'Please select an item in the list.',
          TEXTAREA: 'Please fill out this field.'
        },
        typeMismatch: {
          email: 'Please enter an e-mail address.',
          url: 'Please enter a URL.'
        },
        rangeUnderflow: {
          date: 'Value must be %s or later.',
          month: 'Value must be %s or later.',
          week: 'Value must be %s or later.',
          time: 'Value must be %s or later.',
          datetimeLocale: 'Value must be %s or later.',
          number: 'Value must be greater than or equal to %s.',
          range: 'Value must be greater than or equal to %s.'
        },
        rangeOverflow: {
          date: 'Value must be %s or earlier.',
          month: 'Value must be %s or earlier.',
          week: 'Value must be %s or earlier.',
          time: 'Value must be %s or earlier.',
          datetimeLocale: 'Value must be %s or earlier.',
          number: 'Value must be less than or equal to %s.',
          range: 'Value must be less than or equal to %s.'
        },
        stepMismatch: {
          date: 'You can only select every %s. day in the date calendar.',
          month: 'You can only select every %s. month in the date calendar.',
          week: 'You can only select every %s. week in the date calendar.',
          time: 'You can only select every %s. second in the time picker.',
          datetimeLocale: 'You can only select every %s. second in the time picker.',
          number: 'Please enter a valid value. Only %s and a multiple of %s.',
          range: 'Please enter a valid value. Only %s and a multiple of %s.'
        },
        tooLong: 'Please lengthen this text to %s characters or less (you are currently using %s characters).',
        tooShort: 'Please lengthen this text to %s characters or more (you are currently using %s characters).',
        patternMismatch: 'Please match the request format. %s',
        badInput: {
          number: 'Please enter a number.'
        }
      }
    };
    const selector = 'input, textarea, select';
    let STATUS = 0;

    /**
     * change language from json file
     */
    if (options.language) {
      $.getJSON(options.language, function (json) {
        errorMessages = json;
      });
    }

    /**
     * run validate when form submit
     */
    $(FORM).on('submit', function (event) {
      STATUS = 0;
      checkAll(this, event);
      if (STATUS) {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    // //check form without submit
    // validator.checkAll(); //return error count
    let checkAll = function (form, event) {
      let thisForm = form ? form : FORM;
      let thisEvent = event ? event : '';
      STATUS = 0;
      $(thisForm).find(selector).each((i, el) => {
        validationRun(el, thisEvent);
      });
      return STATUS;
    };

    /**
     * run validate when on input
     */
    let run = function () {
      $(FORM).find(selector).each((i, el) => {
        $(el).off('input');
        $(el).on('input', function (event) {
          validationRun(this, event);
          if (hasAttr(el, 'data-v-equal')) {
            let equal = $(el).attr('data-v-equal');
            $(equal).one('input', function () {
              let id = $(this).attr('id');
              $(`[data-v-equal="#${id}"]`).trigger('input');
            });
          }
        });
      });
    };
    let validationRun = function (el, event) {
      el.setCustomValidity('');
      if (el.checkValidity() !== false) {
        Object.values(validator).map(value => {
          let error = value.call(value, el, event);
          if (error) {
            el.setCustomValidity(error);
          }
        });
      } else {
        if (!options.html5BrowserDefault) {
          el.setCustomValidity(HTML5Default(el));
        }
      }
      if (el.checkValidity() === false) {
        showErrorMessage(el, el.validationMessage);
        STATUS++;
      } else {
        hideErrorMessage(el);
      }
    };

    /**
     * show errors
     * @param el
     * @param message
     */
    let showErrorMessage = function (el, message) {
      var _$$data;
      let isInputGroup = $(el).parents('.input-group').length > 0;
      $(el).removeClass(options.validClass).parent().removeClass(options.validClass);
      $(el).addClass(options.invalidClass).parent().addClass(options.invalidClass);
      message = (_$$data = $(el).data('vMessage')) !== null && _$$data !== void 0 ? _$$data : message;
      if (options.errorMessage) {
        let group = $(el).parent();
        if (isInputGroup) {
          let invalidFeedBack = $(group).parent().find('.' + options.invalidFeedBackClass);
          if ($(invalidFeedBack).length) {
            $(invalidFeedBack).html(message);
          } else if (isInputGroup) {
            group.parent().append(`<div class="${options.invalidFeedBackClass}">${message}</div>`);
          }
        } else if ($(group).length) {
          let invalidFeedBack = $(group).find(`.${options.invalidFeedBackClass}`);
          if ($(invalidFeedBack).length) {
            $(invalidFeedBack).html(message);
          } else {
            $(group).append(`<div class="${options.invalidFeedBackClass}">${message}</div>`);
          }
        }
      }
    };
    let hideErrorMessage = function (el) {
      let isInputGroup = $(el).parents('.input-group').length > 0;
      $(el).removeClass(options.invalidClass);

      //If it's an input group, remove from both places
      $(el).parent().removeClass(options.invalidClass);
      if (options.successClass) {
        $(el).addClass(options.validClass);
        if (isInputGroup) {
          $(el).parent().addClass(options.validClass);
        }
        $(el).parent().removeClass(options.invalidClass);
      }
    };
    let validator = {
      multiSelectMin: function (el) {
        if ($(el).prop('tagName') === 'SELECT' && $(el).prop('multiple')) {
          let mustSelectedCount = $(el).data('vMinSelect');
          let selectedCount = $(el).find('option:selected').length;
          if (selectedCount < mustSelectedCount && ($(el).prop('require') || selectedCount > 0)) {
            return errorMessages.minSelectOption.sprintf(mustSelectedCount);
          }
        }
        return '';
      },
      multiSelectMax: function (el) {
        if ($(el).prop('tagName') === 'SELECT' && $(el).prop('multiple')) {
          let mustSelectedCount = $(el).data('vMaxSelect');
          let selectedCount = $(el).find('option:selected').length;
          if (selectedCount > mustSelectedCount && ($(el).prop('require') || selectedCount > 0)) {
            return errorMessages.maxSelectOption.sprintf(mustSelectedCount);
          }
        }
        return '';
      },
      equal: function (el) {
        let equal = $(el).data('vEqual');
        if (equal) {
          let title = $(equal).attr('title');
          if ($(equal).val() !== $(el).val()) {
            return errorMessages.equal.sprintf(title ? title : '');
          }
        }
        return '';
      },
      groupCheckBox: function (el, event) {
        if (hasAttr(el, 'type', 'checkbox')) {
          let checkGroup = $(el).closest('[data-checkbox-group]');
          let mustCheckedCount = $(checkGroup).data('vMinSelect');
          let checkedCount = checkGroup.find('input[type=checkbox]:checked').length;
          let groupRequired = typeof $(checkGroup).data('vRequired') === 'undefined' ? 0 : 1;
          if (checkGroup) {
            if (typeof event.originalEvent !== 'undefined' && event.originalEvent.type === 'input') {
              $(checkGroup).find('input[type=checkbox]').each((i, item) => {
                $(item).trigger('input');
              });
            }
            if (checkedCount < mustCheckedCount && (groupRequired || checkedCount > 0)) {
              if ($(el).prop('checked') === false) {
                return errorMessages.groupCheckBox.sprintf(mustCheckedCount);
              }
            }
          }
        }
        return '';
      },
      customMin: function (el) {
        if (hasAttr(el, 'data-v-min')) {
          let mustMin = $(el).data('vMin');
          let value = $(el).val();
          if (isNaN(value) && ($(el).prop('require') || value.length > 0)) {
            return errorMessages.number;
          }
          if (value < mustMin && ($(el).prop('require') || value.length > 0)) {
            return errorMessages.minValue.sprintf(mustMin);
          }
        }
        return '';
      },
      customMax: function (el) {
        if (hasAttr(el, 'data-v-max')) {
          let mustMax = $(el).data('vMax');
          let value = $(el).val();
          if (isNaN(value) && ($(el).prop('require') || value.length > 0)) {
            return errorMessages.number;
          }
          if (value > mustMax && ($(el).prop('require') || value.length > 0)) {
            return errorMessages.maxValue.sprintf(mustMax);
          }
        }
        return '';
      },
      customMinLength: function (el) {
        if (hasAttr(el, 'data-v-min-length')) {
          let mustMin = $(el).data('vMinLength');
          let value = $(el).val().length;
          if (value < mustMin && ($(el).prop('require') || value > 0)) {
            return errorMessages.minLength.sprintf(mustMin, value);
          }
        }
        return '';
      },
      customMaxLength: function (el) {
        if (hasAttr(el, 'data-v-max-length')) {
          let mustMax = $(el).data('vMaxLength');
          let value = $(el).val().length;
          if (value > mustMax && ($(el).prop('require') || value > 0)) {
            return errorMessages.maxLength.sprintf(mustMax, value);
          }
        }
        return '';
      },
      fileMinSize: function (el) {
        if (hasAttr(el, 'type', 'file')) {
          let size = $(el).data('vMinSize');
          for (let i = 0; i < el.files.length; i++) {
            if (size && size > el.files[i].size) {
              return errorMessages.fileMinSize.sprintf(size);
            }
          }
        }
        return '';
      },
      fileMaxSize: function (el) {
        if (hasAttr(el, 'type', 'file')) {
          let size = $(el).data('vMaxSize');
          for (let i = 0; i < el.files.length; i++) {
            if (size && size < el.files[i].size) {
              return errorMessages.fileMaxSize.sprintf(size);
            }
          }
        }
        return '';
      }
    };

    /**
     * HTML 5 default error to selected language
     * @param el
     * @returns {null|jQuery|HTMLElement|undefined|string|*}
     * @constructor
     */
    let HTML5Default = function (el) {
      var _el$validationMessage;
      if (el.validity.valueMissing) {
        if (el.tagName === 'INPUT') {
          if (typeof errorMessages.HTML5.valueMissing.INPUT[el.type] === 'undefined') {
            return errorMessages.HTML5.valueMissing.INPUT.default;
          } else {
            return errorMessages.HTML5.valueMissing.INPUT[el.type];
          }
        } else {
          if (typeof errorMessages.HTML5.valueMissing[el.tagName] !== 'undefined') {
            return errorMessages.HTML5.valueMissing[el.tagName];
          }
        }
      } else if (el.validity.typeMismatch) {
        if (typeof errorMessages.HTML5.typeMismatch[el.type] !== 'undefined') {
          return errorMessages.HTML5.typeMismatch[el.type];
        }
      } else if (el.validity.rangeOverflow) {
        if (typeof errorMessages.HTML5.rangeOverflow[el.type] !== 'undefined') {
          var _el$getAttribute;
          let max = (_el$getAttribute = el.getAttribute('max')) !== null && _el$getAttribute !== void 0 ? _el$getAttribute : null;
          if (el.type === 'date' || el.type === 'month') {
            let date = new Date(max);
            max = date.toLocaleDateString();
          }
          if (el.type === 'week') {
            max = 'Week ' + max.substr(6);
          }
          return errorMessages.HTML5.rangeOverflow[el.type].sprintf(max);
        }
      } else if (el.validity.rangeUnderflow) {
        if (typeof errorMessages.HTML5.rangeUnderflow[el.type] !== 'undefined') {
          var _el$getAttribute2;
          let min = (_el$getAttribute2 = el.getAttribute('min')) !== null && _el$getAttribute2 !== void 0 ? _el$getAttribute2 : null;
          if (el.type === 'date' || el.type === 'month') {
            let date = new Date(min);
            min = date.toLocaleDateString();
          }
          if (el.type === 'week') {
            min = 'Week ' + min.substr(6);
          }
          return errorMessages.HTML5.rangeUnderflow[el.type].sprintf(min);
        }
      } else if (el.validity.stepMismatch) {
        if (typeof errorMessages.HTML5.stepMismatch[el.type] !== 'undefined') {
          var _el$getAttribute3;
          let step = (_el$getAttribute3 = el.getAttribute('step')) !== null && _el$getAttribute3 !== void 0 ? _el$getAttribute3 : null;
          if (el.type === 'date' || el.type === 'month') {
            let date = new Date(step);
            step = date.toLocaleDateString();
          }
          if (el.type === 'week') {
            step = 'Week ' + step.substr(6);
          }
          return errorMessages.HTML5.stepMismatch[el.type].sprintf(step, step);
        }
      } else if (el.validity.tooLong) {
        var _el$getAttribute4;
        let minLength = (_el$getAttribute4 = el.getAttribute('maxlength')) !== null && _el$getAttribute4 !== void 0 ? _el$getAttribute4 : null;
        let value = $(el).val();
        return errorMessages.HTML5.tooLong.sprintf(minLength, value.length);
      } else if (el.validity.tooShort) {
        var _el$getAttribute5;
        let maxLength = (_el$getAttribute5 = el.getAttribute('minlength')) !== null && _el$getAttribute5 !== void 0 ? _el$getAttribute5 : null;
        let value = $(el).val();
        return errorMessages.HTML5.tooShort.sprintf(maxLength, value.length);
      } else if (el.validity.patternMismatch) {
        var _el$getAttribute6;
        if (hasAttr(el, 'pattern') && hasAttr(el, 'title')) {
          return $(el).attr('title');
        }
        let pattern = (_el$getAttribute6 = el.getAttribute('pattern')) !== null && _el$getAttribute6 !== void 0 ? _el$getAttribute6 : null;
        return errorMessages.HTML5.patternMismatch.sprintf(pattern);
      } else if (el.validity.badInput) {
        if (typeof errorMessages.HTML5.badInput[el.type] !== 'undefined') {
          return errorMessages.HTML5.badInput[el.type];
        }
      }
      return (_el$validationMessage = el.validationMessage) !== null && _el$validationMessage !== void 0 ? _el$validationMessage : '';
    };

    /**
     * triger error
     * @param el
     * @param message
     */

    let errorTrigger = function (el, message) {
      if (typeof el === 'object') {
        el = el[0];
      }
      el.setCustomValidity(message);
      showErrorMessage(el, el.validationMessage);
    };

    //reload instance after dynamic element is added
    // validator.reload();
    let reload = function () {
      run();
    };

    /**
     * attr equal with value or has attr
     * @param el
     * @param attr
     * @param value
     * @returns {boolean}
     */
    function hasAttr(el, attr, value = '') {
      let val = $(el).attr(attr);
      if (typeof val !== typeof undefined && val !== false) {
        if (value) {
          if (value === val) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }

    /**
     * php sprintf alternate
     * @returns {string}
     */
    String.prototype.sprintf = function () {
      var output = this.toString();
      for (var i = 0; i < arguments.length; i++) {
        var asNum = parseFloat(arguments[i]);
        if (asNum || asNum == 0) {
          var suffix = asNum > 1 || asNum == 0 ? 's' : '';
          output = output.replace(/%s {1}(\w+)\(s\){1}/, '%s $1' + suffix);
        }
        output = output.replace('%s', arguments[i]);
      }
      return output;
    };
    run();
    return {
      validator,
      errorTrigger,
      reload,
      checkAll
    };
  };
})(jQuery);